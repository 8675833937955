import _isEmpty from 'lodash/isEmpty';

export const matchMediaQuery = (query) => !__SERVER__ && window && window.matchMedia(query).matches;

// istanbul ignore next
export const exposeSkuSwatches = (galleryData) => {
    const { organicZoneInfo: { products } = {} } = galleryData;

    window.getSkuSwatches = function getSkuSwatches() {
        let skuSwatches = [];
        if (!_isEmpty(products)) {
            skuSwatches = products.map(({ ppId, skuSwatch }) => ({ ppId, skuSwatch }));
        }
        return skuSwatches;
    };
};

export const hasBreadCrumbLinks = (
    breadCrumbInfo,
    { isBot = false },
    hideBreadCrumbsWhenNoNavigation
) => {
    return !_isEmpty(breadCrumbInfo) && (isBot || !hideBreadCrumbsWhenNoNavigation)
        ? true
        : !_isEmpty(breadCrumbInfo) &&
              breadCrumbInfo.some((el) => {
                  return el.navigationState && el.navigationState.length > 0;
              });
};

export const exposeEnsembleData = (galleryData) => {
    const { organicZoneInfo: { products = [] } = {} } = galleryData;

    window.getEnsembleData = function getEnsembleData() {
        let ensembleData = [];
        if (!_isEmpty(products)) {
            ensembleData = products
                .filter(({ entityType = [] }) => entityType.includes('ENSEMBLE'))
                .map((product) => ({
                    name: product.name,
                    ppId: product.ppId,
                    priceRange: {
                        currentMin: product.currentMin,
                        currentMax: product.currentMax,
                        originalMin: product.originalMin,
                        originalMax: product.originalMax,
                    },
                    imagesInfo: product.imagesInfo,
                    bundleIndicator: product.bundleIndicator,
                    ensembleImageUrls: product.ensembleImageUrls,
                    upperLeftBadge: product.upperLeftBadge,
                }));
        }
        return ensembleData;
    };
};

export const getMappedStyliticsURL = (catId, requestUrl) => {
    const productionHostname = 'www.jcpenney.com';
    let queryParam = ['?id='];

    if (!__SERVER__ && window) {
        const { search = '' } = window?.location;
        queryParam = search.split(search.match(catId));
    }

    const mappedURL = productionHostname + requestUrl + queryParam[0] + catId;
    return mappedURL;
};

export const getProductColorwayIDs = (products) => {
    const colorwayIDs = [];
    const parseName = (name) => {
        return name.toLowerCase().replace(/ /g, '-');
    };

    products.forEach((product) => {
        const { productList } = product;

        productList?.forEach((item) => {
            const { ppId, skuSwatch } = item;

            if (ppId) {
                const colorway = skuSwatch && parseName(skuSwatch[0]?.colorName);
                const remoteId = `${ppId}_${colorway}`;

                colorwayIDs.push(remoteId);
            }
        });
    });

    return colorwayIDs.join(',');
};
