import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool, string } from 'prop-types';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import YodaDropdown from 'yoda-site-components/lib/components/YodaDropdown/YodaDropdown';
import { isDesktopWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import { detectSafari } from 'yoda-core-components/lib/helpers/Utils/Utils';
import { updateUrl, getURLParameterBykey } from '../../utils';
import { showLoader } from '../../actions/LoaderActions';
import * as styles from './SortBy.css';

const cx = classNames.bind(styles);

/* istanbul ignore next */
const SortBy = ({ className, displayDesktopView, renderBrPreviewSkeletonGrid }) => {
    const {
        context: { deviceType: { isMobile = false } = {} } = {},
        sortOptions: { sortOptions = [] } = {},
        featureFlags: { enableSortByFilters = true } = {},
    } = useSelector((store) => store);

    const dispatch = useDispatch();

    const [isOrientationChange, setOrientation] = useState(isDesktopWidth());

    const handleOrientationChange = () => {
        const setOriention =
            (window.orientation && Math.abs(window.orientation) === 90) || isDesktopWidth();
        setOrientation(setOriention);
    };

    useEffect(() => {
        if (!isMobile) {
            window.addEventListener('orientationchange', () => handleOrientationChange());
            return () =>
                window.removeEventListener('orientationchange', () => handleOrientationChange());
        }
        return undefined;
    }, []);

    const onChange = (event) => {
        const preSorBy = getURLParameterBykey('sort', window.location.href);
        const currSorBy = getURLParameterBykey('sort', event);
        if (enableSortByFilters && preSorBy !== currSorBy) {
            dispatch(showLoader());
        }
        updateUrl(event, null, null, {});
    };
    const parseSortOptions = sortOptions.map(({ apiUrl: value = '', name: displayKey = '' }) => ({
        displayKey,
        value,
    }));

    const isIpad = () => {
        if (!__SERVER__ && window?.navigator) {
            const { userAgent } = navigator;
            if (
                (/iPad/.test(userAgent) || (detectSafari() && navigator?.maxTouchPoints > 0)) &&
                !window?.MSStream
            ) {
                return true;
            }
        }
        return false;
    };

    const selectSortValue = () => {
        let defaultValue = null;

        /* istanbul ignore next */
        if (sortOptions) {
            for (let i = 0; i < sortOptions.length; i += 1) {
                if (sortOptions[i].selected === true) {
                    defaultValue = sortOptions[i].apiUrl;

                    if (displayDesktopView) defaultValue = sortOptions[i].name;

                    break;
                }
            }
        }

        return defaultValue;
    };

    const MobileLabel = () => {
        const labelClasses = dt([
            'cursor-pointer',
            'absolute',
            'w-full',
            'h-full',
            'left-0',
            'opacity-0',
        ]);

        return (
            <label htmlFor="sortByFilter" className={labelClasses}>
                Sort By
            </label>
        );
    };

    const sortWrapperDesktopClasses = `${dt([
        'border-1',
        renderBrPreviewSkeletonGrid ? 'border-gray-15' : 'border-gray-60',
        'border',
        'h-12',
        'w-64',
        'border-solid',
        'lg:flex',
        'sm:hidden',
        'items-center',
        'relative',
        'rounded-md',
        'sm:-top-[1.25px]',
        renderBrPreviewSkeletonGrid ? 'bg-light-white' : '',
    ])} ${cx('sort-wrapper-desktop', className)}`;

    const sortWrapperMobileClasses = `${dt(['lg:hidden', 'sm:block'])} ${cx(
        'sort-wrapper-mobile',
        className
    )}`;
    return displayDesktopView ? (
        <div className={sortWrapperDesktopClasses}>
            {!renderBrPreviewSkeletonGrid ? (
                <YodaDropdown
                    automationId="sortDropdown-desktop"
                    dataSource={parseSortOptions}
                    defaultValue={selectSortValue()}
                    dropdownLabel="Sort By: "
                    dropdownTheme={cx('desktop-dropdown-theme')}
                    onChange={onChange}
                    theme={cx('desktop-dropdown-wrapper-theme')}
                    tooltipWrapper={cx('desktop-dropdown-wrapper-theme')}
                />
            ) : null}
        </div>
    ) : (
        <div className={sortWrapperMobileClasses}>
            <MobileLabel />
            <YodaDropdown
                automationId="sortDropdown-mobile"
                dataSource={parseSortOptions}
                defaultValue={selectSortValue()}
                id="sortByFilter"
                mobileTheme={cx('mobile-dropdown-theme', {
                    'dropdown-static': !isIpad(),
                })}
                onChange={onChange}
                isMobileViewinDesktop
                key={isOrientationChange}
            />
        </div>
    );
};

SortBy.defaultProps = {
    className: '',
    displayDesktopView: false,
    renderBrPreviewSkeletonGrid: false,
};

SortBy.propTypes = {
    className: string,
    displayDesktopView: bool,
    renderBrPreviewSkeletonGrid: bool,
};

export default SortBy;
