/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { object, oneOf, shape, string } from 'prop-types';
import get from 'lodash/get';
import getBrowserHistory from 'yoda-core-components/lib/navigation/history/getBrowserHistory';
import { getIrisTemplateData } from 'yoda-site-components/lib/actions/IrisAction';
import { getCookie } from 'yoda-interfaces/lib/helpers/Cookies';
import IrisPage from 'yoda-site-components/lib/components/IrisPage/IrisPage';
import Layout from 'yoda-site-components/lib/components/Layout/Layout';
import ScrollTop from 'yoda-site-components/lib/components/ScrollTop/ScrollTop';
import isEmpty from 'lodash/isEmpty';
import irisComponentsForGallery from 'yoda-site-components/lib/iris/components/forGallery';
import {
    selectShouldRender,
    selectIsNative,
    selectPreferences,
} from 'yoda-site-components/lib/selectors/ContextSelector';
import MiniPDPInterstitialAsync from 'yoda-mini-pdp/lib/components/MiniPDPInterstitial/MiniPDPInterstitialAsync';
import isBloomReachAudience, {
    enableBloomReachPage,
} from 'yoda-site-components/lib/helpers/BloomReach/BloomReach';

import makeQuery from 'yoda-core-components/lib/navigation/query/makeQuery';
import LocalStorage, {
    SessionStorage,
} from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import RestClient from 'yoda-interfaces/lib/RestClient/RestClient';

import { findMobileOS } from 'yoda-core-components/lib/helpers/Utils/Utils';
import { showLoader } from '../../../actions/LoaderActions';
import GalleryLoader from '../../../components/GalleryLoader/GalleryLoader';

import { getGalleryDetailsById, initiatePendingActions } from '../../../actions/GalleryAction';
import {
    getGalleryContentWithIris,
    setInitialIrisRequestMode,
} from '../../../actions/GalleryIrisAction';

import { patterns, catIdRedirect } from '../../../configurations/config';
import {
    galleryPostProcessorForIris,
    getIrisParamForGallery,
} from '../../../helpers/IrisApiCallingFunctions';
import redirectRequired from '../../../helpers/RedirectHelper';

import { getIrisFallBackStatus } from '../../../selectors/IrisFailure';
import {
    addToRecentSearch,
    compareUrls,
    isContentHydratedWithIris,
    isFirstPageWithoutPrev,
    getURLParameterBykey,
    isHydrated,
    updateUrl,
    getDeviceType,
    getContentDate,
} from '../../../utils';
import SeoSchema from '../../../components/SeoSchema/SeoSchema';
import StoreAnalytics from '../../../components/StoreAnalytics/StoreAnalytics';
import TimeoutError from '../../../components/TimeoutError/TimeoutError';
import FailOverLayout from '../../../components/FailOverLayout/FailOverLayout';
import gridZoneRender from '../../../iris/zoneOverrides/grid';
import aboveTheGridZoneOverride from '../../../iris/zoneOverrides/aboveTheGrid';
import irisPageSpecificComponents from '../../../iris/components';
import { fail } from './constants';

/**
 * IRIS integration wiki: https://wiki.jcpenney.com/pages/viewpage.action?pageId=79664545
 * Please update if logic changes are made in this file.
 */
/* istanbul ignore next */

export const IrisWrapper = ({ location, pageType, cookies: { DPCPT = '' } = {} }) => {
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    const refreshContent = useSelector((state) => state?.refreshContent);
    const hostname = useSelector((state) => get(state?.context, 'hostname', ''));
    const seoConfig = useSelector((state) => get(state?.context?.preferences, 'seoConfig', {}));
    const userSegAB = useSelector((state) => get(state?.context?.preferences, 'userSegAB', ''));
    const enableSmoothScrollIOSGallerySearch = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableSmoothScrollIOSGallerySearch', false)
    );
    const plpBloomreachInclusionList = useSelector(
        (state) => state?.context?.preferences.plpBloomreachInclusionList,
        shallowEqual
    );
    const requestUrl = useSelector((state) => get(state?.context, 'requestUrl', ''));
    const isNative = useSelector((state) => selectIsNative(state));
    const plpSwitchWidgetPreferences =
        useSelector((state) => selectPreferences(state))?.plpSwitchWidget || {};
    const irisFallBackStatus = useSelector((state) => getIrisFallBackStatus(state));
    const shouldRender = useSelector((state) => selectShouldRender(state));
    const { deviceType: { isBot = false } = {}, isPreview = false } = useSelector(
        ({ context }) => context
    );
    const commonDataRef = useRef();
    useSelector(
        (state) => state?.commonData,
        (_, b) => {
            commonDataRef.current = b;
            return true;
        }
    );
    const commonData = commonDataRef.current || {};

    const irisData = useSelector((state) => state?.irisData);

    const irisCallStatus = useSelector((state) => state?.irisContent?.irisCallStatus, shallowEqual);

    const irisContentCallCompleted = useSelector(
        (state) => state?.irisContent?.contentCallCompleted,
        shallowEqual
    );

    const enableMultiSelectFilters = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableMultiSelectFilters', false)
    );
    const showBackToTop = useSelector((state) =>
        get(state?.context?.featureFlags, 'showBackToTop', false)
    );
    const enableCanonicalFallback = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableCanonicalFallback', true)
    );
    const enableABTest = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableABTest', false)
    );
    const disableIrisCall = useSelector((state) =>
        get(state?.context?.featureFlags, 'disableIrisCall', false)
    );

    const enableSSRForPLP = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableSSRForPLP', false)
    );
    const enableV1Redirect = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableV1Redirect', true)
    );
    const isClientSideRenderingEnabled = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableRealTimeInventory', true)
    );
    const enableDefaultFilterForPreview = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableDefaultFilterForPreview', false)
    );
    const enableBloomReach = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableBloomReach', false)
    );

    const enableBloomReachV3PLP = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableBloomReachV3PLP', true)
    );

    const enableBloomReachNative = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableBloomReachNative', false)
    );

    const enableBrAbTestAlgo = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableBrAbTestAlgo', true)
    );

    const enableMiniPDPFeature = useSelector((state) =>
        get(state?.context?.featureFlags, 'enableMiniPDPFeature', false)
    );

    const postProcessor = galleryPostProcessorForIris.bind({
        getGalleryContentWithIris: getGalleryContentWithIris(),
    });

    const loadIrisGalleryContent = useCallback(
        (loc, initialLoad) => {
            if (!disableIrisCall) {
                if (!__SERVER__ && initialLoad) {
                    dispatch(setInitialIrisRequestMode());
                }
                const params = getIrisParamForGallery(loc);
                const aggregateAPI = true;
                dispatch(
                    getIrisTemplateData(
                        params,
                        aggregateAPI,
                        postProcessor,
                        undefined,
                        undefined,
                        undefined,
                        false,
                        false,
                        initialLoad,
                        false
                    )
                );
            }
        },
        [__SERVER__],
        disableIrisCall
    );

    const getAbTestParams = useCallback(() => {
        let payload = [];
        if (!__SERVER__ && enableABTest && pageType === 'Gallery') {
            const pageURL = get(window.location, 'href', '');
            const referrerURL = get(window.location, 'origin', '');
            payload = [
                {
                    pageURL,
                    referrerURL,
                },
            ];
            return payload;
        }
        return {};
    }, [!__SERVER__, enableABTest, pageType]);

    const getPreviewParams = useCallback(
        (initialLoad, loc = {}, context = {}) => {
            let params = {};
            const urlStatus = getURLParameterBykey('item_status', loc.search);
            const facetId = getURLParameterBykey('activeFacetId', loc.search);
            if (!__SERVER__ && context.isPreview) {
                params = {
                    channel: getDeviceType((context && context.deviceType) || {}),
                    contentDate: getContentDate(),
                };
            }

            // added default preview param see MNPYGD-2946
            if (
                initialLoad &&
                !urlStatus &&
                context.isPreview &&
                enableDefaultFilterForPreview &&
                facetId !== '44710'
            ) {
                params = { ...params, item_status: 1 };
            }
            return params;
        },
        [__SERVER__]
    );

    const loadGalleryDetails = useCallback(
        (loc, initialLoad, isNative, isBloomReachV3Page = false) => {
            if (loc) {
                const abTestParams = getAbTestParams();

                const updatedSearch = () => {
                    let newSearch = loc.search;
                    const isSearchPage = pageType === 'Search';
                    const isNativeBloomReach = enableBloomReachNative ? false : isNative;
                    const bloomReachAudience = isBloomReachAudience(
                        enableBloomReach,
                        isNativeBloomReach
                    );

                    // for TOFP2P-2699: introduced as part of filter shifting ab test. set in preference for future tests
                    if (
                        userSegAB &&
                        !getURLParameterBykey('UsrSeg', loc.search) &&
                        !isBloomReachV3Page
                    ) {
                        const useSegABValue = getCookie(userSegAB);
                        if (useSegABValue) newSearch = newSearch.concat(`&${useSegABValue}`);
                    }

                    if (
                        bloomReachAudience &&
                        isSearchPage &&
                        !getURLParameterBykey('UsrSeg', loc.search)
                    ) {
                        const brUID = Cookies?.load('_br_uid_2', true);
                        const brTestAlgo = enableBrAbTestAlgo
                            ? Cookies?.load('BRTestAlgo', true)
                            : null;
                        newSearch = newSearch.concat(`&UsrSeg=${Cookies?.load('BRtest', true)}`);
                        if (brUID) newSearch = newSearch.concat(`&_br_uid_2=${brUID}`);
                        if (brTestAlgo?.length > 0) newSearch = newSearch.concat(`&${brTestAlgo}`);
                    }
                    if (isBloomReachV3Page) {
                        const brUID = Cookies?.load('_br_uid_2', true);
                        if (isPreview) {
                            const switchWidgetKey = !isSearchPage
                                ? 'plpSwitchWidget'
                                : 'srpSwitchWidget';
                            const selectedValue = SessionStorage.getData(
                                `${switchWidgetKey}SelectedValue`,
                                true
                            );
                            const defaultValue = isEmpty(selectedValue)
                                ? plpSwitchWidgetPreferences?.[`${switchWidgetKey}DefaultValue`] ||
                                  (!isSearchPage ? 'Solr' : 'Bloomreach')
                                : selectedValue;
                            if (defaultValue === 'Bloomreach') {
                                if (brUID) newSearch = newSearch.concat(`&_br_uid_2=${brUID}`);
                            }
                        } else {
                            const brTestAlgo =
                                enableBrAbTestAlgo && isSearchPage
                                    ? Cookies?.load('BRTestAlgo', true)
                                    : null;
                            if (brUID) newSearch = newSearch.concat(`&_br_uid_2=${brUID}`);
                            if (brTestAlgo?.length > 0)
                                newSearch = newSearch.concat(`&${brTestAlgo}`);
                        }
                    }
                    return newSearch;
                };

                if (!__SERVER__ && enableSmoothScrollIOSGallerySearch && findMobileOS() === 'iOS') {
                    // only for PLP/SRP
                    if (document.documentElement.classList.contains('scroll-smooth')) {
                        document.documentElement.classList.remove('scroll-smooth');
                    }
                }

                const query = makeQuery(updatedSearch());
                const params = {
                    ...query,
                    ...getPreviewParams(initialLoad, loc, RestClient.getContext()),
                };
                let apiPath;

                if (query?.id && loc?.pathname?.match(catIdRedirect.pattern)) {
                    // Old Cat Id Url. We have to perform a 301 to new url
                    apiPath = catIdRedirect?.apiPath; // eslint-disable-line
                    params.id = query.id;
                } else {
                    apiPath = loc.pathname.substr(1);
                }

                dispatch(
                    getGalleryDetailsById({
                        loc,
                        params,
                        apiPath,
                        initialLoad,
                        abTestParams,
                        pageType,
                    })
                );
                // only for PLP/SRP - TOFP2P-2681 Scroll is Abruptly stopping in ios device
                if (!__SERVER__ && enableSmoothScrollIOSGallerySearch && findMobileOS() === 'iOS') {
                    if (!document.documentElement.classList.contains('scroll-smooth')) {
                        setTimeout(() => {
                            document.documentElement.classList.add('scroll-smooth');
                        }, 1500);
                    }
                }
            }
        },
        [isNative, pageType, getURLParameterBykey]
    );

    if (!mounted) {
        // Code for componentWillMount here
        // This code is called only one time before intial render

        const redirectStatus = redirectRequired(commonData, location?.pathname);
        let invokeDetailsFromClient = isClientSideRenderingEnabled && !isBot;
        let invokedIrisClientSide = false;
        const isFirstPage = isFirstPageWithoutPrev();
        const marketingTilesCount = isFirstPage ? 0 : getURLParameterBykey('mktTiles');
        const isGalleryPage = pageType === 'Gallery';
        const isSearchPage = pageType === 'Search';

        const isBloomReachV3Page = enableBloomReachPage(
            enableBloomReachV3PLP,
            requestUrl,
            plpBloomreachInclusionList
        );

        if (__SERVER__) {
            if (enableV1Redirect) {
                const { gallery: { flags, pattern } = {} } = patterns?.v1patterns;

                const v1GalleryPattern = new RegExp(pattern, flags);

                invokeDetailsFromClient =
                    invokeDetailsFromClient && !v1GalleryPattern.test(location?.pathname);
            }

            const ssrThrottleEnabledForPLP = DPCPT?.includes('CP4Y');

            /**
             * Load gallery details for the following:
             *   enableSSRForPLP is true per YODATOF-147
             *   existing v1 redirects
             */
            const shouldLoadGalleryDetails = [
                !isBot,
                !isSearchPage,
                enableSSRForPLP,
                ssrThrottleEnabledForPLP,
            ].every((condition) => condition);

            if (!redirectStatus) {
                if (shouldLoadGalleryDetails || !invokeDetailsFromClient) {
                    loadGalleryDetails(location, false, isNative, isBloomReachV3Page);
                }

                loadIrisGalleryContent(location, true);
            }
        } else {
            window.jcpDLjcp = {};
            let locationHref = window?.location?.href;
            const invokeGalleryDetails = !isHydrated(location);

            // When unable to render server-side (e.g. Server Too Busy) get all content zones.
            if (!isContentHydratedWithIris(irisData)) {
                loadIrisGalleryContent(location, true);
                invokedIrisClientSide = true;
            }

            /**
             * Load gallery details for the following:
             *   existing v1 redirects
             *   server-side failed to load
             *   server-side is disabled
             */
            if (invokeGalleryDetails || (invokeDetailsFromClient && !enableSSRForPLP)) {
                loadGalleryDetails(location, true, isNative, isBloomReachV3Page);
            } else {
                dispatch(initiatePendingActions());
            }

            /* istanbul ignore next */
            const history = getBrowserHistory();
            history.listen((loc) => {
                const currentLocation = window.location.href;
                const isReplaceAction = history?.action === 'REPLACE';
                const isBackBtn = history?.action === 'POP';
                const isCurrentLocation = locationHref === currentLocation;
                const isNoResultsPage = !!LocalStorage.getData('NoResultsPage');

                if (isBackBtn && !isNoResultsPage) {
                    const browserBFButtonClick = LocalStorage.getData('browserBFButtonClick');
                    LocalStorage.setData(
                        'browserBFButtonClick',
                        browserBFButtonClick !== 'undoBtn'
                    );
                    if (locationHref.indexOf('page=') !== -1) {
                        LocalStorage.setData('paginationClickBack', true);
                    }
                    dispatch(showLoader());
                }

                if (!isReplaceAction && (!isCurrentLocation || refreshContent)) {
                    locationHref = currentLocation;
                    loadIrisGalleryContent(loc, false);

                    if (isGalleryPage || isSearchPage) {
                        loadGalleryDetails(loc, isNoResultsPage, isNative, isBloomReachV3Page);
                    }
                }
            });
        }

        isSearchPage && addToRecentSearch();
    }

    useEffect(() => {
        setMounted(true);
    }, []);

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, []);
        return ref.current;
    };

    const { pathname } = location;
    const { seoTitleTags = {} } = commonData;
    const { canonicalURL } = seoTitleTags;

    const prevValue = usePrevious({
        seoTitleTags,
        canonicalURL,
        irisCallStatus,
        irisContentCallCompleted,
        location: { pathname },
    });

    const hasCallStatus = prevValue?.irisCallStatus === irisCallStatus;
    const isCallCompleted = prevValue?.irisContentCallCompleted === irisContentCallCompleted;

    useEffect(() => {
        /* istanbul ignore next */
        if (pathname && prevValue?.seoTitleTags && seoTitleTags) {
            if (
                prevValue?.canonicalURL &&
                !compareUrls(pathname, prevValue?.canonicalURL) &&
                !compareUrls(
                    canonicalUrlPreviewRemove(canonicalURL),
                    canonicalUrlPreviewRemove(prevValue?.canonicalURL)
                )
            ) {
                // Update URL if canonical and original don't match.
                updateUrl(prevValue?.canonicalURL, {}, true);
            }
        }
    }, [!hasCallStatus || !isCallCompleted]);

    const canonicalUrlPreviewRemove = (url = '') => {
        let urlReturn = url;
        if (hostname === 'preview.jcpenney.com') {
            urlReturn = url?.replace(/preview\//i, '');
        }
        return urlReturn;
    };

    const irisParams = getIrisParamForGallery(location);
    const irisPageProps = {
        aggregateAPI: true,
        'data-automation-id': pageType,
        irisClientCall: false,
        irisComponents: irisComponentsForGallery,
        irisPageSpecificComponents,
        pageType,
        params: irisParams,
        postProcessor,
        theme: 'gallery',
        zoneRenderer: [
            {
                zone: 'ABVGRID',
                renderer: aboveTheGridZoneOverride,
            },
            {
                renderer: gridZoneRender,
                zone: 'GRID',
            },
        ],
    };
    const PageLayout = (showIrisPage) => (
        <Layout>
            <>
                {!enableMultiSelectFilters && <GalleryLoader />}
                <SeoSchema />
                <StoreAnalytics />
                <TimeoutError />
                <ScrollTop showBackToTop={showBackToTop} />
                {showIrisPage ? (
                    <IrisPage {...irisPageProps} />
                ) : (
                    <FailOverLayout
                        enableCanonicalFallback={enableCanonicalFallback}
                        location={location}
                        pageType={pageType}
                        seoConfig={seoConfig}
                        hostname={hostname}
                    />
                )}

                {enableMiniPDPFeature && <MiniPDPInterstitialAsync />}
            </>
        </Layout>
    );
    if (irisFallBackStatus || (irisCallStatus === fail && irisContentCallCompleted)) {
        return PageLayout(false);
    }

    return shouldRender ? (
        PageLayout(true)
    ) : (
        <Layout>
            <IrisPage {...irisPageProps} />
        </Layout>
    );
};

IrisWrapper.propTypes = {
    location: object,
    pageType: oneOf(['Gallery', 'Search']).isRequired,
    cookies: shape({ DPCPT: string }),
};

IrisWrapper.defaultProps = {
    location: {},
    cookies: {},
};

export default IrisWrapper;
