export const BOPISErrors = {
    invalidZipCodeMes: 'Please enter a valid ZIP code.',
    noAvailability:
        'Sorry, no availability at stores within 50 miles. Please enter a new ZIP code.',
    noResults: "Sorry! We can't find any stores within 50 miles of your search.",
    noResultsForCovid19:
        'Sorry, no availability at stores within 50 miles. Please enter a new ZIP code.',
    prePopulateError: 'Error while prepopulating stores',
    somethingWentWrong: 'Sorry, unable to find stores.',
    storesResultsZeroProducts:
        'Sorry! These items are not available at a store within 50 miles of your search.',
};

export const isGalleryURL = '/g/';

export const noSearchResult = {
    findAStore: 'Find a store.',
    noProductsFound: 'Sorry, no products were found matching:',
    searchTips: 'SEARCH TIPS',
    suggestionList: [
        {
            id: 'suggestion-1',
            text: 'Double check your spelling for typos.',
        },
        {
            id: 'suggestion-2',
            text: 'Use general term(s) or fewer keywords.',
        },
        {
            id: 'suggestion-3',
            text: 'Try searching for an item that is less specific and refine results.',
        },
        {
            id: 'suggestion-4',
            text: 'If you are looking for a specific terms from a printed ad.',
        },
        {
            id: 'suggestion-5',
            text: 'Enter the search term or web id as shown.',
        },
    ],
    suggestionWithStoreLink: 'Some product available in store may not be available online.',
    tryNewKeyword: 'Try a new keyword',
};

export const prevNoResultsMessage =
    'The previous results are shown because your last filters produced 0 results.';

export const source = 'rrplacementtype=';
export const url = 'url';
