import { detectisEdge, detectisIE } from 'yoda-core-components/lib/helpers/Utils/Utils';

export const scrollTitleIntoView = (scrollElement, isDesktop, isNative, centerScroll) => {
    // Don't scroll on page load or if element isn't found
    const scrollable = document.querySelector(scrollElement);
    if (window.scrollY === 0 || !scrollable) {
        return;
    }

    const BUFFER = 10; /* buffer height between the title and header */
    const STICKY_HEADER_HEIGHT =
        !isNative && document.getElementById('header')?.getBoundingClientRect().height;
    let topOfElement = window.pageYOffset + (scrollable.getBoundingClientRect().top - BUFFER);

    if (isNative) {
        topOfElement = window.pageYOffset + (scrollable?.getBoundingClientRect().top - BUFFER);
    } else if (!isDesktop) {
        topOfElement =
            window.pageYOffset +
            (scrollable?.getBoundingClientRect().top - STICKY_HEADER_HEIGHT - BUFFER);
    }

    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

    if (centerScroll) {
        scrollable.scrollIntoView({ block: 'center' });
    } else if (supportsNativeSmoothScroll) {
        window.scroll({ top: topOfElement, behavior: 'smooth' });
        document.documentElement.scrollTop = topOfElement;
    } else {
        window.scroll({ top: topOfElement, behavior: 'smooth' });
    }

    /*
     * YODATOF-313 - window scroll does not anchor to the elements needed
     */
    if (detectisIE() && !centerScroll) {
        document.documentElement.scrollTop = topOfElement;
    }
    if (detectisEdge() && !centerScroll) {
        document.body.scrollTop = topOfElement;
    }
};

export default scrollTitleIntoView;
