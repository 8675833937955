import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import {
    getPageType,
    getProductsPerPage,
    getSearchTermFromUrl,
    getURLParameterBykey,
} from '../utils';
import { defaultViewType } from '../configurations/config';
import getBreadCrumbInfo from '../selectors/BreadCrumbs';

export const getCategoryObj = (stateObj, pageType) => {
    const categoryObj = {
        department: pageType,
        primaryCategory: pageType,
        primaryCategoryId: '',
        subCategory1: '',
        subCategoryId1: '',
        subCategory2: '',
        subCategoryId2: '',
    };
    // getBreadCrumbInfo will take care of breadCrumbs data even if iris is not enable
    const breadCrumbs = getBreadCrumbInfo(stateObj);

    stateObj.countOfSearchResults &&
        breadCrumbs.forEach((data, index) => {
            const { breadCrumbLabel, catId = '', deptId = '' } = data;
            if (index === 0) {
                categoryObj.department = breadCrumbLabel;
                categoryObj.departmentId = deptId;
                categoryObj.primaryCategory = breadCrumbLabel;
                categoryObj.primaryCategoryId = catId;
                categoryObj.subCategory1 = '';
                categoryObj.subCategoryId1 = '';
                categoryObj.subCategory2 = '';
                categoryObj.subCategoryId2 = '';
            } else {
                categoryObj[`subCategory${index}`] = breadCrumbLabel;
                categoryObj[`subCategoryId${index}`] = catId;
            }
        });

    return categoryObj;
};

const getFacetValues = (list, taxonomyKey, enableTaxonomyForAnalytics) => {
    return (
        list?.map((facet) => {
            let value = {
                position: '',
                name: facet?.name.toLowerCase() || '',
            };
            if (enableTaxonomyForAnalytics) value = { ...value, taxonomyKey };
            return value;
        }) ?? []
    );
};
const getSearchTermCollectionFromFacets = (state) => {
    let searchTermCollectionValue = '';
    // eslint-disable-next-line consistent-return
    state.selectedFilters?.forEach((selectedFilter) => {
        const facetInternameList = ['brand', 'item_type', 'product_type', 'gender', 'color'];
        const facetInternalName = _get(selectedFilter, 'facetInternalName', '').toLowerCase();
        if (facetInternameList.includes(facetInternalName)) {
            const paramByKey = getURLParameterBykey(facetInternalName);
            // if defined facet intername existsis in URL should not log anlytics searchTermCollectionValue
            if (paramByKey) return '';
            if (facetInternalName === 'brand') {
                searchTermCollectionValue = searchTermCollectionValue
                    ? `${searchTermCollectionValue}|brand`
                    : 'brand';
            } else if (facetInternalName === 'item_type') {
                searchTermCollectionValue = searchTermCollectionValue
                    ? `${searchTermCollectionValue}|itemtype`
                    : 'itemtype';
            } else if (facetInternalName === 'product_type') {
                searchTermCollectionValue = searchTermCollectionValue
                    ? `${searchTermCollectionValue}|producttype`
                    : 'producttype';
            } else if (facetInternalName === 'gender') {
                searchTermCollectionValue = searchTermCollectionValue
                    ? `${searchTermCollectionValue}|gender`
                    : 'gender';
            } else if (facetInternalName === 'color') {
                searchTermCollectionValue = searchTermCollectionValue
                    ? `${searchTermCollectionValue}|color`
                    : 'color';
            }
        }
    });
    return searchTermCollectionValue;
};

const getfacetArray = (state, storesSelected) => {
    let count = 0;
    const filters = [];
    const { context: { featureFlags: { enableTaxonomyForAnalytics } = {} } = {} } = state;
    if (storesSelected && storesSelected.length) {
        count += 1;
        filters.push({
            keyPosition: count,
            key: 'same day pickup',
            values: getFacetValues(
                storesSelected,
                'Store_Availability',
                enableTaxonomyForAnalytics
            ),
        });
    }

    state.selectedFilters?.forEach((selectedFilter) => {
        const facetName = _get(selectedFilter, 'facetName', '').toLowerCase();
        const facetInternalName = _get(selectedFilter, 'facetInternalName', '').toLowerCase();
        count += 1;
        filters.push({
            keyPosition: count,
            key: facetName,
            values: getFacetValues(
                selectedFilter.facetValueList,
                facetInternalName,
                enableTaxonomyForAnalytics
            ),
        });
    });

    const promotedFilter = state.promotedFilterSelected;
    if (promotedFilter && promotedFilter.value === 'interactivevisnav') {
        const interactiveVizNavFilter = {
            keyPosition: filters.length + 1,
            key: 'interactivevisnav',
            values: [
                { position: '', name: promotedFilter.heading, taxonomyKey: 'interactivevisnav' },
            ],
        };
        filters.push(interactiveVizNavFilter);
    }
    return filters;
};

const getSortBy = (state) => {
    if (state.sortOptions.length > 0) {
        return state.sortOptions.find((opt) => opt?.selected === true)?.name.toLowerCase() || '';
    }

    if (!_isArray(state.sortOptions) && state.sortOptions) {
        let sort = '';
        /* eslint-disable */
        for (const key in state.sortOptions) {
            sort =
                state.sortOptions[key]?.find((opt) => opt?.selected === true)?.name.toLowerCase() ||
                '';
            if (sort) break;
        }

        return sort;
    }

    return '';
};
const getSearchType = (state) => {
    const requestUrl = _get(state, 'commonData.requestUrl', '');
    const mode = getURLParameterBykey('mode', requestUrl);
    const autoCorrect = _get(state, 'seoTags.autoCorrectedMessage', null);
    const enableOnsiteSearchAnalyticsUpdates = _get(
        state,
        'context.featureFlags.enableOnsiteSearchAnalyticsUpdates',
        true
    );
    let type = getURLParameterBykey('searchType') || 'regular';
    if (type.match(/predictive/i)) {
        type = 'recommended';
    } else if (type.match(/recent/i)) {
        type = 'recent';
    } else if (type.match(/related/i)) {
        type = 'related';
    } else if (getURLParameterBykey('redirectTerm')) {
        type = 'redirect';
    } else if (enableOnsiteSearchAnalyticsUpdates && autoCorrect) {
        type = 'autocorrect';
    }
    if (LocalStorage.getData('isRecentSearch')) {
        type = 'recent';
    }
    LocalStorage.removeData('isRecentSearch');
    if (mode === '1') {
        return `${type}|full`;
    } else if (mode === '2') {
        return `${type}|partial`;
    }
    return type;
};

const getSelectedPageNumber = (store) => {
    return (
        (store.pagination &&
            store.pagination.pagination &&
            store.pagination.pagination.selectedPageNumber) ||
        null
    );
};

const getFirstTenPPIDs = (store) => {
    const firstTenPPIDs = []; // first page top ten products for tracking analytics
    if (getSelectedPageNumber(store) === 1) {
        const products =
            (store.productList &&
                store.productList.products &&
                store.productList.products[0] &&
                store.productList.products[0].productList) ||
            store.products ||
            [];
        for (let i = 0; i < products.length; i += 1) {
            if (!products[i] || firstTenPPIDs.length === 10) {
                break;
            }
            if (products[i].ppId) {
                firstTenPPIDs.push(products[i].ppId);
            }
        }
    }
    return firstTenPPIDs;
};

const getPaginationValues = (page, attr) => {
    if (page && page.pagination) {
        return page.pagination[attr] || '';
    }
    return '';
};

const getSearchTerm = (seoTags) => {
    return seoTags.autoCorrectedMessage ? seoTags.autoCorrectedMessage : getSearchTermFromUrl();
};

const getProductList = (state) => {
    if (!state?.productList?.products) {
        return [];
    }
    return state.productList.products
        .reduce((acc, item) => {
            if (item?.productList) {
                return acc.concat(item.productList);
            }
            return acc;
        }, [])
        .map(item => ({
            ppID: item?.ppId || (item?.marketingSlot ? 'marketingTile' : ''),
            productSource: item?.ppId
                ? (item?.sponsoredProduct ? 'sponsored' : 'regular')
                : (item?.marketingSlot ? 'marketing' : '')
        }));
};

export const setsbResultsInfo = (state, lastFilterParam, storesSelected, pageLoad, shouldDisplaySponsoredAds) => {
    const {
        context: {
            featureFlags: {
                enableThreeColumnGrid = true,
                enableOnsiteSearchAnalyticsUpdates = true,
            } = {},
        } = {},
    } = state;
    const gridViewType = enableThreeColumnGrid ? '3 grid' : defaultViewType;
    let lastFilter = '';

    if (lastFilterParam) {
        lastFilter = lastFilterParam.name ? lastFilterParam : '';
    }

    
    
    const sbResultsInfo = {
        searchTerm: getSearchTerm(state.seoTags || {}).toLowerCase(),
        defaultSearchTerm: getSearchTermFromUrl().toLowerCase(),
        lastFilter,
        numberOfResults: `${getPaginationValues(state.pagination, 'totalProductsCount')}`,
        resultsPageNumber: `${getPaginationValues(state.pagination, 'selectedPageNumber')}`,
        sortByType: getSortBy(state),
        filters: getfacetArray(state, storesSelected),
        resultsPerPage: `${getProductsPerPage((state.context && state.context.preferences) || {})}`,
        firstTenPPIDs: getFirstTenPPIDs(state),
        viewType: getURLParameterBykey('view') || gridViewType,
        ...(shouldDisplaySponsoredAds && { productList: getProductList(state) }),
    };

    if (getPageType() !== 'g') {
        sbResultsInfo.typeOfSearch = getSearchType(state);
        const searchTermCollection = getSearchTermCollectionFromFacets(state);
        if (searchTermCollection) {
            sbResultsInfo.searchTermCollection = searchTermCollection;
        }

        if (
            enableOnsiteSearchAnalyticsUpdates &&
            sbResultsInfo?.typeOfSearch?.indexOf('redirect') !== -1
        ) {
            const redirectTerm = getURLParameterBykey('redirectTerm');
            if (redirectTerm) sbResultsInfo.defaultSearchTerm = redirectTerm.toLowerCase();
        }

        if (
            enableOnsiteSearchAnalyticsUpdates &&
            sbResultsInfo?.typeOfSearch?.indexOf('recommended') !== -1
        ) {
            const predSearchTerm = getURLParameterBykey('predSearchTerm');
            if (predSearchTerm) sbResultsInfo.defaultSearchTerm = predSearchTerm.toLowerCase();
        }
    }

    if (getPageType() === 'g') {
        const enableDinamicVisNav = _get(state, 'context.featureFlags.enableDynamicVisNav', false);
        sbResultsInfo.sortSearchStrategy = _get(
            state,
            'productList.galleryDetails.analyticsSortKeys',
            ''
        );
        sbResultsInfo.algorithmicFilterSorting = _get(
            state,
            'commonData.galleryFacetReorderingIndicator',
            false
        )
            ? 'yes'
            : 'no'; //as per the story YODATOF-1169 and confirmed with the business for now this will apply only for /g
        if (enableDinamicVisNav) {
            sbResultsInfo.dynamicVisualMapAttribute =
                (state?.dynamicVisNavReducer || []).length > 0;
        }
    }

    if (sbResultsInfo.typeOfSearch === 'regular' && pageLoad && sbResultsInfo.filters.length) {
        sbResultsInfo.typeOfSearch = 'recommended';
    }

    return sbResultsInfo;
};
