/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bool, func, object, oneOfType, string } from 'prop-types';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import BOPISToolTipReducer from 'yoda-site-components/lib/reducers/BOPISToolTipReducer';
import BOPISToolTipSaga from 'yoda-site-components/lib/sagas/BOPISToolTipSaga';
import withReducerSaga from 'yoda-site-components/lib/hoc/ReducerRegistry/withReducerSaga';
import { askUserCurrentLocation } from 'yoda-site-components/lib/actions/NativeAppAction';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import {
    isDesktopWidth,
    isTabletWidth,
    isMobileWidth,
} from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import Tooltip from 'yoda-site-components/lib/components/YodaTooltip/YodaTooltip';
import CustomFacet from './CustomFacet';
import {
    formatLocationServiceData,
    getURLParameterBykey,
    updateQueryStringParameter,
    updateStoresToUrl,
} from '../../../../utils';
import * as styles from './BOPISFacet.css';
// eslint-disable-next-line import/no-named-as-default
import StoresQuickInfo from '../../../StoresQuickInfo/StoresQuickInfo';
import {
    getUserGeoLocation,
    enablePrePopulateStores,
    getUserNativeGeoLocation,
} from '../../../../actions/BOPISActions';

const cx = classNames.bind(styles);
let displayTooltipOnLoad = false;
let displayStoresOnRefresh = false;

/* istanbul ignore next */
const BOPISFacet = (props) => {
    const { facetConfig, placeHolder, parentSection } = props;
    const { apiUrl } = facetConfig;

    const dispatch = useDispatch();

    const customDeepEqual = (previous, current) => {
        return isEqual(previous, current);
    };

    const {
        displayLoader = false,
        headerStore: selectedStore = {},
        params: { zipCode = '' } = {},
        selectedStores: selectedStoresFilter = [],
        sortedStoresList,
        disableSelectStore: disabled = false,
    } = useSelector((store) => get(store, 'bopisInfo', {}), customDeepEqual);

    const {
        featureFlags: { enableLocationService = false },
        isNative = false,
        messagesTexts: {
            lineItemCurbSideText: {
                lineItemCurbSidePickupAvailable: curbsidePickupLabel = '',
            } = {},
        } = {},
    } = useSelector((store) => get(store, 'context', {}), customDeepEqual);

    const locationService = useSelector(
        (store) => get(store, 'locationServiceReducer', {}),
        customDeepEqual
    );

    const nativeAppLatLong = useSelector(
        (store) => get(store, 'nativeAppLatLongLocation', {}),
        customDeepEqual
    );

    const {
        inputZipCode: previousInputZipCode = '',
        zipcode: previousZipCode = '',
    } = locationService;

    const displayStoreInfo = useRef(false);

    const tooltip = useRef();

    const BOPISFacet = useRef(null);

    const prevProps = useRef(null);

    const [considerStore, setConsiderStore] = useState(null);
    const [displayInTooltip, setDisplayInTooltip] = useState(true);
    const [displayName, setDisplayName] = useState('Select Store');
    const [hasCurbsidePickup, setHasCurbsidePickup] = useState(false);
    const [isMaxWidth768, setIsMaxWidth768] = useState(false);
    const [isMinWidth1024, setIsMinWidth1024] = useState(false); // 10
    const [inSlider, setInSlider] = useState(false);
    const [isPortraitOrientation, setIsPortraitOrientation] = useState(true); // 12
    const [showStoresInContainer, setShowStoresInContainer] = useState(false);
    const [storeSelected, setStoreSelected] = useState(false);
    const [updateStoreIdToUrl, setUpdateStoreIdToUrl] = useState(null); // 15

    // eslint-disable-next-line camelcase
    useEffect(() => {
        setStoreNameToDisplay();

        const BOPISFacetRef = BOPISFacet.current;
        const inSlider =
            BOPISFacetRef && !!BOPISFacetRef.closest('[data-component-name=GallerySlider]');

        const maxWidth768Mql = window.matchMedia('screen and (max-width: 767px)');
        const minWidth1024Mql = window.matchMedia('screen and (min-width: 1279px)');
        const portraitOrientationMql = window.matchMedia('screen and (orientation: portrait)');

        setDisplayInTooltip(!inSlider);
        setInSlider(inSlider);
        setIsMaxWidth768(maxWidth768Mql.matches);
        setIsMinWidth1024(minWidth1024Mql.matches);
        setIsPortraitOrientation(portraitOrientationMql.matches);

        /**
         * Prior to Safari 14, MediaQueryList is based on EventTarget, so you must use
         * addListener() and removeListener() to observe media query lists.
         *
         * Work-around: dynamically calling add/removeEventListener or add/removeListener
         * based on browser support.
         */
        // TODO: Once support for Safari 13 and below ends, remove this work-around.
        const hasMqlEventListener = !!portraitOrientationMql.addEventListener;
        const mqlListenerType = hasMqlEventListener ? 'Event' : '';

        const maxWidth768MqlListenerParams = hasMqlEventListener
            ? ['change', handleMaxWidth768Change]
            : [handleMaxWidth768Change];

        const minWidth1024MqlListenerParams = hasMqlEventListener
            ? ['change', handleMinWidth1024Change]
            : [handleMinWidth1024Change];

        const portraitOrientationMqlListenerParams = hasMqlEventListener
            ? ['change', handleOrientationChange]
            : [handleOrientationChange];

        if (!isDesktopWidth()) {
            portraitOrientationMql[`add${mqlListenerType}Listener`](
                ...portraitOrientationMqlListenerParams
            );

            maxWidth768Mql[`add${mqlListenerType}Listener`](...maxWidth768MqlListenerParams);
            minWidth1024Mql[`add${mqlListenerType}Listener`](...minWidth1024MqlListenerParams);
        }

        prevProps.current = {
            locationService,
            nativeAppLatLong,
        };

        return () => {
            if (!isDesktopWidth()) {
                portraitOrientationMql[`remove${mqlListenerType}Listener`](
                    ...portraitOrientationMqlListenerParams
                );
                maxWidth768Mql[`remove${mqlListenerType}Listener`](...maxWidth768MqlListenerParams);
                minWidth1024Mql[`remove${mqlListenerType}Listener`](
                    ...minWidth1024MqlListenerParams
                );
            }
        };
    }, []);

    useEffect(() => {
        setStoreNameToDisplay();

        const {
            locationService: {
                inputZipCode: currentInputZipCode = '',
                zipcode: currentZipCode = '',
            },
            nativeAppLatLong: nextnativeAppLatLong,
        } = prevProps.current;

        if (currentInputZipCode && currentInputZipCode !== previousInputZipCode) {
            dispatch(enablePrePopulateStores());
        } else if (previousZipCode && previousZipCode !== currentZipCode) {
            dispatch(enablePrePopulateStores());
        }
        if (isNative && !storeSelected) {
            const { lat: nextLat, long: nextLong } = nextnativeAppLatLong;
            const { lat, long } = nativeAppLatLong;
            if (lat !== nextLat && long !== nextLong && nextLat && nextLong) {
                const position = {
                    lat: nextLat,
                    lng: nextLong,
                };
                dispatch(getUserNativeGeoLocation(position));
            }
        }

        prevProps.current = {
            locationService,
            nativeAppLatLong,
        };
    }, [locationService, isNative, storeSelected, nativeAppLatLong]);

    const handleMaxWidth768Change = ({ matches: isMaxWidth768 }) => {
        setIsMaxWidth768(isMaxWidth768);
    };

    const handleMinWidth1024Change = ({ matches: isMinWidth1024 }) => {
        setIsMinWidth1024(isMinWidth1024);
    };

    // set state whenever orientation changes to reflect the appropriate
    // view style (e.g. desktop view for tablet landscape mode)
    const handleOrientationChange = ({ matches: isPortraitOrientation }) => {
        setIsPortraitOrientation(isPortraitOrientation);
    };

    const setStoreNameToDisplay = () => {
        const closestStore = formatLocationServiceData(locationService);
        const considerStore = getStoreToConsider();
        const urlStores = getURLParameterBykey('storeIds');
        const passURLStoreCheck = !facetConfig.selected || urlStores;
        const selectedStoreLength = selectedStoresFilter.length;

        let curbsidePickupIndicator = considerStore && considerStore.curbsidePickupIndicator;
        let displayName =
            enableLocationService && closestStore ? closestStore.name : 'Select Store';
        let setNewState = true;
        let storeSelected = false;
        let updateStoreIdToUrl;

        if (passURLStoreCheck && (considerStore || selectedStoreLength)) {
            let selectedStoreDisplayName;

            if (selectedStoreLength > 1) {
                selectedStoreDisplayName = `${selectedStoreLength} Stores`;
            } else if (considerStore) {
                selectedStoreDisplayName = considerStore.name;
                curbsidePickupIndicator = considerStore.curbsidePickupIndicator;
                updateStoreIdToUrl = considerStore.id;

                if (facetConfig.selectDefaultStore) {
                    setNewState = false;
                    facetConfig.action = true;
                    facetConfig.storeId = considerStore.id;
                    // https://jira.jcpenney.com/browse/MNPDPYODA-10946
                    // this bug was introduced because updateStoresToUrl was invoked
                    // multiple times on mobile since FilterHeader and FilterPanel
                    // both render BOPISFacet. don't call updateStoresToUrl a second
                    // time on mobile.
                    // TODO: refactor FilterHeader and FilterPanel to fix this bug ^
                    !inSlider && updateStoresToUrl(facetConfig, sortedStoresList);
                }
            }

            displayName = selectedStoreDisplayName;
            storeSelected = true;
        }

        // Updating a local storage value with nearest store details to use in PDP
        if (selectedStoreLength) {
            const lastSelectedStore = selectedStoresFilter[0];
            LocalStorage.setData(
                'gallerySDPUStoreInfo',
                {
                    curbsidePickupIndicator: lastSelectedStore.curbsidePickupIndicator,
                    storeId: lastSelectedStore.id,
                    storeName: lastSelectedStore.name,
                    zipCode,
                },
                true
            );
        }

        if (setNewState) {
            const displayInTooltip = !inSlider;

            setDisplayInTooltip(displayInTooltip);
            setDisplayName(displayName);
            setHasCurbsidePickup(!!(curbsidePickupIndicator && curbsidePickupLabel));
            setStoreSelected(storeSelected);
            if (updateStoreIdToUrl) {
                setUpdateStoreIdToUrl(updateStoreIdToUrl);
            }
        }
    };

    const onFacetChange = () => {
        displayStoreInfo.current = !displayStoreInfo.current;
        displayInTooltip
            ? tooltip.current.toggleDisplayContent(displayStoreInfo.current)
            : toggleStoresInContainer();
    };

    const toggleStoresInContainer = () => {
        setShowStoresInContainer(!showStoresInContainer);
    };

    const storesOnChangeFilter = () => {
        displayStoresOnRefresh = true;
    };

    const storesOnChange = (displayTooltip = true, isStoreChecked) => {
        if (!displayTooltip) {
            facetConfig.selected = isStoreChecked;
            tooltip.current.containerClicked = false;
            tooltip.current.toggleDisplayContent(false);
        }

        displayTooltipOnLoad = true;
    };

    const tooltipCallBackFun = (currentState) => {
        displayStoreInfo.current = currentState;
        displayTooltipOnLoad = false;

        // If Akamai fails to set a store invoke geo location API to fetch nearest store.
        if (enableLocationService && !storeSelected && currentState) {
            if (isNative) {
                dispatch(askUserCurrentLocation());
            } else {
                dispatch(getUserGeoLocation());
            }
        }
    };

    const getStoreToConsider = () => {
        const considerStore = enableLocationService
            ? formatLocationServiceData(locationService)
            : selectedStore;

        setConsiderStore(considerStore);

        return considerStore;
    };

    const renderBopisHeader = () => {
        const buttonSliderStyles = displayName === 'Select Store' ? '' : dt(['pl-1']);
        const buttonNoOldCommonServiceStyles = dt(['sm:text-small', 'lg:text-xsmall']);
        const buttonDisableStyles = disabled && dt(['cursor-not-allowed']);
        const buttonStyles = dt(['overflow-hidden', 'text-black', 'underline']);
        const tooltipTextStyles = dt([
            'font-open-sans-regular',
            'overflow-hidden',
            'relative',
            'text-black',
            'text-left',
            'underline',
            'whitespace-nowrap',
        ]);
        const tooltipTextOldCommonServiceStyles = dt(['sm:text-small', 'lg:text-xsmall']);
        const tooltipDisabledStyles = disabled && dt(['cursor-not-allowed']);

        const bopisHeaderConfig = {
            button: {
                className: cx(
                    buttonStyles,
                    buttonDisableStyles,
                    buttonNoOldCommonServiceStyles,
                    buttonSliderStyles,
                    'tooltip-text'
                ),
                'data-automation-id': 'stores-quickinfo-btn',
                onClick: toggleStoresInContainer,
                disabled,
            },
            storeLabel: {
                className: dt(['flex', 'whitespace-nowrap', 'items-center']),
            },
            tooltip: {
                customToolTip: true,
                automationId: `stores-quickinfo-tooltip-${parentSection}`,
                customTooltipTheme: {
                    tooltipContentClass: `${dt([
                        'mx-2',
                        'my-0',
                        'rounded-sm',
                        'shadow-elevation2',
                    ])} ${cx('tooltip-content')}`,
                    tooltipTextClass: cx(
                        tooltipTextStyles,
                        tooltipTextOldCommonServiceStyles,
                        tooltipDisabledStyles,
                        'tooltip-text'
                    ),
                    tooltipWrapperClass: `${
                        displayName === 'Select Store'
                            ? dt(['overflow-hidden', 'w-full'])
                            : dt(['overflow-hidden', 'w-full', 'pl-1'])
                    } ${cx('tooltip-wrapper', {
                        selectStoreTooltipWrapper: displayName === 'Select Store',
                    })}`,
                },
                disabled,
                dynamicPosition: false,
                enableOverlay: true,
                onClick: tooltipCallBackFun,
                tooltip,
                tooltipData: (
                    <StoresQuickInfo
                        facetConfig={facetConfig}
                        storesOnChange={storesOnChange}
                        placeHolder={placeHolder}
                    />
                ),
                visibleOnLoad: displayTooltipOnLoad,
            },
        };

        const getBOPISMessage = useCallback(() => {
            if (displayInTooltip) {
                if (displayName === 'Select Store')
                    return (
                        <Tooltip ref={tooltip} {...bopisHeaderConfig.tooltip}>
                            {displayName}
                        </Tooltip>
                    );

                return (
                    <div {...bopisHeaderConfig.storeLabel}>
                        {displayName}{' '}
                        <Tooltip ref={tooltip} {...bopisHeaderConfig.tooltip}>
                            change
                        </Tooltip>
                    </div>
                );
            }

            if (displayName === 'Select Store') {
                return (
                    <button {...bopisHeaderConfig.button} type="button">
                        {displayName}
                    </button>
                );
            }

            return (
                <div {...bopisHeaderConfig.storeLabel}>
                    {displayName}
                    <button {...bopisHeaderConfig.button} type="button">
                        change
                    </button>
                </div>
            );
        }, [
            displayInTooltip,
            displayName,
            bopisHeaderConfig.customTooltipTheme,
            bopisHeaderConfig.tooltipData,
            apiUrl,
        ]);

        const bopisHeaderStyles = dt([
            'flex',
            'items-center',
            'text-black',
            'sm:text-base',
            'lg:text-small',
            'sm:relative',
            'smOnly:pl-3',
            '!smOnly:left-[56px]',
        ]);
        const bopisHeaderNewSmallScreenStyles = 'bopisHeaderNewSmallScreen';
        const bopisHeaderNotUseOldCommonServiceStyles = dt(['capitalize']);

        return (
            <div
                className={cx(
                    'bopis-header',
                    bopisHeaderStyles,
                    bopisHeaderNewSmallScreenStyles,
                    bopisHeaderNotUseOldCommonServiceStyles
                )}
                id="bopisHeader"
            >
                {getBOPISMessage()}
            </div>
        );
    };

    const bopisFacetConfig = { ...facetConfig };

    const facetButtonStyles = dt([
        'capitalize',
        'font-open-sans-bold',
        'sm:text-base',
        'lg:text-small',
    ]);
    const facetButtomMobile = (isMobileWidth() || isTabletWidth()) && 'store-details';
    const facetButtonCurbSideSliderStyles =
        hasCurbsidePickup && inSlider && isPortraitOrientation && dt(['mb-1']);
    const facetButtonOldCommonServiceSliderStyles =
        isMobileWidth() ||
        (inSlider && isMaxWidth768) ||
        (inSlider && isTabletWidth() && hasCurbsidePickup) ||
        (isTabletWidth() && !isMinWidth1024 && !inSlider);
    const faceButtonNotOldServiceStyles = 'mx-4';

    const facetAdditionalProps = {
        buttonClasses: cx(
            facetButtonStyles,
            facetButtomMobile,
            facetButtonCurbSideSliderStyles,
            facetButtonOldCommonServiceSliderStyles,
            faceButtonNotOldServiceStyles
        ),
    };

    // Update the store ID to URL if user has a default store.
    if (updateStoreIdToUrl) {
        bopisFacetConfig.apiUrl = updateQueryStringParameter(
            facetConfig.apiUrl,
            'storeIds',
            updateStoreIdToUrl
        );
    }

    // If stores are not selected then pass onFacetChange to Facet component.
    if (!storeSelected) {
        facetAdditionalProps.onFacetChange = onFacetChange;
        facetAdditionalProps.disable = !!storeSelected;
    }

    bopisFacetConfig.facetName = bopisFacetConfig.name;
    bopisFacetConfig.isStoreSelected = storeSelected;
    bopisFacetConfig.displayName = displayName;
    // YODATOF-1607: span added to pass style attribute to fix flex not working in older Safari versions
    // TODO: remove this span work-around once Safari versions older than 14 are no longer supported
    bopisFacetConfig.name =
        isMobileWidth() ||
        (isTabletWidth() &&
            ((isPortraitOrientation && isMaxWidth768) ||
                (!isPortraitOrientation && isMinWidth1024))) ? (
            'same day pickup'
        ) : (
            <span>same day pickup</span>
        );

    const curbSideLabelStyles = dt(['capitalize', 'text-black', 'text-xsmall', 'smOnly:pl-3']);
    const curbSideLabelNotInSliderStyles = !inSlider && dt(['sm:hidden', 'lg:block']);
    const curbSideLabelNotOldServiceStyles = dt(['sm:!ml-14', 'lg:!ml-16', 'md:!ml-16']);
    const CurbsidePickup = () =>
        hasCurbsidePickup && curbsidePickupLabel ? (
            <div
                className={cx(
                    curbSideLabelStyles,
                    curbSideLabelNotOldServiceStyles,
                    curbSideLabelNotInSliderStyles
                )}
                data-automation-id={`curbside-pickup-label-${parentSection}`}
            >
                {curbsidePickupLabel}
            </div>
        ) : null;
    const facetContainerStyles = dt(['bg-white', 'flex', 'relative']);
    const facetContainerLoaderStyles = displayLoader && cx('BOPISToolTipLoader', 'loader');
    const facetContentNotSliderStyles = !inSlider && dt(['lg:flex-col', 'items-center']);
    const facetContentSliderStyles = inSlider && dt(['flex-col']);
    const facetContentSliderStylesPadding = inSlider && dt(['smOnly:px-6', 'smOnly:py-4']);

    return (
        <div
            className={cx(
                'wrapper',
                facetContainerStyles,
                facetContentSliderStyles,
                facetContainerLoaderStyles
            )}
            data-component-name="BOPISFacet"
            ref={BOPISFacet}
        >
            <div
                className={cx(
                    facetContentNotSliderStyles,
                    facetContentSliderStyles,
                    facetContentSliderStylesPadding
                )}
            >
                <CustomFacet
                    {...{
                        ...props,
                        considerStore,
                        facetConfig: bopisFacetConfig,
                        inSlider,
                        ...facetAdditionalProps,
                    }}
                />
                {renderBopisHeader()}
                <CurbsidePickup />
            </div>
            <StoresQuickInfo
                {...{
                    facetConfig,
                    inSlider: true,
                    placeHolder,
                    renderComponent: inSlider && (displayStoresOnRefresh || showStoresInContainer),
                    storesOnChange: storesOnChangeFilter,
                }}
            />
        </div>
    );
};

BOPISFacet.propTypes = {
    facetConfig: oneOfType([object, func]).isRequired,
    hideCurbsidePickupLabel: bool,
    placeHolder: string,
    parentSection: bool,
};

BOPISFacet.defaultProps = {
    hideCurbsidePickupLabel: false,
    placeHolder: '',
    parentSection: '',
};

export default compose(withReducerSaga([BOPISToolTipReducer], [BOPISToolTipSaga]))(BOPISFacet);
